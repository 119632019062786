<template>

  <b-overlay
    :show="loading"
    rounded="sm"
  >

      <b-alert
          v-if="!extract && error"
          variant="danger"
          show
          class="m-2"
        >

          <div class="alert-body">
            <span><strong>Error </strong> {{ error }}</span>

          </div>

      </b-alert>


    <div v-if="extract">
      <b-row class="match-height">
        <b-col
          xl="3"
          lg="4"
          md="4"
          sm="6"
        >
          <router-link :to="{name:'shipments'}">
            <statistic-card-vertical
              icon="PackageIcon"
              :statistic="extract.shipments || '--'"
              statistic-title="Shipments"
              color="info"
            />
          </router-link>

        </b-col>

        <b-col
          xl="3"
          l="4"
          md="4"
          sm="6"
        >
          <router-link :to="{name:'configurations'}">
            <statistic-card-vertical
              icon="SettingsIcon"
              :statistic="extract.configurations || '--'"
              statistic-title="Configurations"
              color="info"
            />
          </router-link>

        </b-col>

      </b-row>
    </div>

  </b-overlay>

</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BLink, BOverlay, BAlert
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BAlert,
    BLink,
    BRow,
    BCol,
    StatisticCardVertical,
    BOverlay,
    ToastificationContent,
  },

  data() {
    return {
      loading: false,
      extract: null,
      error:null
    }
  },
  mounted() {
    this.loading = true

    this.$store.dispatch('app/statsExtract')
      .then(result => {
        this.extract = result.data
      },
      error => {
        this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading extracts', 'danger')
        this.error = error.response?.data?.message || error?.message
      }).finally(() => {
        this.loading = false
      })
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },

}
</script>

<style>

</style>
